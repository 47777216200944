.document-form {
    margin-top: 24px;
}

.actionButton {
    min-width: 150px;
    width: 30%;
    max-width: 200px;
    margin-top: 24px;
}

button[disabled] {
    cursor: not-allowed;
}