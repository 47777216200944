@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.dropzone {
    .card {
        border: none;
    }
    .card-body {
        border: 2px dashed $secondary;
        height: 150px;
    }
    .upload-icon {
        width: 35px;
    }
    .upload-text {
        margin-top: 6px;
        display: inline-block;
    }
}