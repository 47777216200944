.notary {
    padding-top: 24px;

    .card-body {
        position: relative;
        padding-top: 36px;
        padding-bottom: 36px;
        .progress-bar {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            border-radius: 0;
            height: 20px;
        }
    }
}

.notary-address {
    font-size: 12px;
    line-height: 24px;
    position: relative;
    top: 1px;
}